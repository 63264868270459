var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"owner-registration-send-code",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-form',{staticClass:"owner-registration-send-code__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',{staticClass:"owner-registration-send-code__form-row"},[_c('h2',{staticClass:"owner-registration-send-code__form-row-title"},[_vm._v(" Подтвердите номер телефона "+_vm._s(_vm.phone)+"."),_c('br'),_vm._v(" Введите код из СМС ")]),_c('v-form-field',{attrs:{"rules":"required|CodeLength:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"type":"number","placeholder":"Код","is-error":!!validationErrors.length},model:{value:(_vm.enteredCode),callback:function ($$v) {_vm.enteredCode=$$v},expression:"enteredCode"}})]}}],null,true)}),_c('v-timer',{staticClass:"owner-registration-send-code__timer",attrs:{"time":40},scopedSlots:_vm._u([{key:"timer",fn:function(ref){
var resetTimer = ref.resetTimer;
var currentTime = ref.currentTime;
return [_c('v-button',{staticClass:"owner-registration-send-code__resend-code-button",attrs:{"type":"button","is-text":"","disabled":!!currentTime},on:{"click":function($event){return _vm.sendCodeToOwnerAgain(resetTimer, reset)}}},[_vm._v(" Отправить код повторно"+_vm._s(currentTime ? (" через " + currentTime + " сек.") : ''))])]}}],null,true)})],1),(!!_vm.backendErrorMessage)?_c('v-form-row',{staticClass:"owner-registration-send-code__error"},[_c('v-form-error',[_vm._v(" "+_vm._s(_vm.backendErrorMessage)+" ")])],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"owner-registration-send-code__footer"},[_c('v-button',{staticClass:"owner-registration-send-code__button--back",on:{"click":function($event){$event.preventDefault();return _vm.$emit('goBack')}}},[_vm._v("Назад")]),_c('v-button',{staticClass:"owner-registration-send-code__button--submit",attrs:{"primary":"","type":"submit"}},[_vm._v("Зарегистрироваться")])],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }