<template>
  <svg
    v-if="direction === 'left'"
    xmlns="http://www.w3.org/2000/svg"
    class="swiper-control-icon"
    width="6"
    height="10"
    fill="none"
  >
    <path d="M5.25.5.75 5l4.5 4.5" stroke="#404042" stroke-linecap="round" />
  </svg>
  <svg
    v-else-if="direction === 'right'"
    xmlns="http://www.w3.org/2000/svg"
    class="swiper-control-icon"
    width="6"
    height="10"
    fill="none"
  >
    <path d="M.75 9.5 5.25 5 .75.5" stroke="#404042" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'VSwiperControl',
  props: {
    direction: {
      type: String,
      required: true,
      validator(value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    }
  }
}
</script>
