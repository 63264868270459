<template>
  <validation-observer v-slot="{ handleSubmit }" tag="div" class="owner-registration-about-owner">
    <v-form class="owner-registration-about-owner__form" @submit.prevent="handleSubmit(onSubmit)">
      <template #default>
        <v-form-row class="owner-registration-about-owner__form-row">
          <h2 class="owner-registration-about-owner__form-row-title">Информация о собственнике</h2>
          <div class="owner-registration-about-owner__form-fields-grid">
            <v-form-field rules="required">
              <template #default="{ validationErrors }">
                <v-input
                  v-model="localUserData.name"
                  placeholder="Имя и фамилия"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
            <v-form-field rules="email|required">
              <template #default="{ validationErrors }">
                <v-input
                  v-model="localUserData.email"
                  placeholder="E-mail"
                  :is-error="!!validationErrors.length"
                  type="email"
                />
              </template>
            </v-form-field>
            <v-form-field rules="required">
              <template #default="{ validationErrors }">
                <v-input-phone
                  v-model="localUserData.phone"
                  placeholder="Телефон"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
          </div>
        </v-form-row>
        <v-form-row class="owner-registration-about-owner__form-row">
          <h2 class="owner-registration-about-owner__form-row-title">Информация об объекте</h2>
          <div class="owner-registration-about-owner__form-fields-grid">
            <v-form-field rules="required|minWithoutDividers:12,:">
              <template #default="{ validationErrors }">
                <v-input
                  v-model="localUserData.houseCadastralNumber"
                  :mask="$options.CADASTRAL_NUMBER_MASK"
                  placeholder="Кадастровый номер дома"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
            <v-form-field rules="required|minWithoutDividers:12,:">
              <template #default="{ validationErrors }">
                <v-input
                  v-model="localUserData.landCadastralNumber"
                  :mask="$options.CADASTRAL_NUMBER_MASK"
                  placeholder="Кадастровый номер участка"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
          </div>
        </v-form-row>
        <v-form-row v-if="!!backendErrorMessage" class="owner-registration-about-owner__error">
          <v-form-error>{{ backendErrorMessage }}</v-form-error>
        </v-form-row>
      </template>
      <template #footer>
        <div class="owner-registration-about-owner__footer">
          <v-checkbox
            v-model="isAgreement"
            :class="{ 'owner-registration-about-owner__agreement-checkbox--checked': isAgreement }"
            class="owner-registration-about-owner__agreement-checkbox"
            >Я принимаю
            <v-button class="owner-registration-about-owner__offer-link" is-text :href="offerUrl" target="_blank"
              >договор оферты</v-button
            >
            и согласен на обработку
            <v-button class="owner-registration-about-owner__offer-link" is-text :href="termsUrl" target="_blank"
              >персональных данных</v-button
            ></v-checkbox
          >
          <v-button
            primary
            type="submit"
            :disabled="!isAgreement"
            class="owner-registration-about-owner__button--submit"
            >Далее</v-button
          >
        </div>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VInput from '@/components/common/VInput.vue'
import VFormError from '@/components/form/VFormError.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import { cloneDeep } from '@/utils/common'
import { CADASTRAL_NUMBER_MASK } from '@/constants/textMask'

export default {
  CADASTRAL_NUMBER_MASK,
  name: 'OwnerRegistrationAboutOwner',
  components: {
    VCheckbox,
    VFormError,
    VInput,
    VForm,
    VFormRow,
    VFormField,
    VInputPhone,
    VButton
  },
  props: {
    backendErrorMessage: {
      type: String,
      default: ''
    },
    userData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    offerUrl: {
      type: String,
      default: ''
    },
    termsUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isAgreement: false,
      localUserData: {}
    }
  },
  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(newUserData) {
        this.localUserData = cloneDeep(newUserData)
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submitted', this.localUserData)
    }
  }
}
</script>
