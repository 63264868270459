<template>
  <match-media v-slot="{ mobile }">
    <div class="owner-registration">
      <header class="owner-registration__header">
        <div class="owner-registration__section-wrapper">
          <v-breadcrumbs
            v-if="!mobile"
            separator="•"
            :items="$options.REGISTRATION_OWNER_BREADCRUMBS"
            class="owner-registration__breadcrumbs"
          />
          <v-button-go-back v-else class="owner-registration__back-button" @click="goToMain" />

          <h2 class="owner-registration__header-title">Регистрация собственника</h2>
          <p class="owner-registration__header-text">
            Необязательно встречаться и подписывать договор. Мы ценим ваше время. Пройдите короткие 2 шага регистрации и
            мы начнем продажу вашего объекта недвижимости в течение 24 часов.
          </p>
        </div>
      </header>
      <div class="owner-registration__how-registration-goes-section">
        <div class="owner-registration__section-wrapper">
          <h2 class="owner-registration__how-registration-goes-title">Как проходит процесс регистрации?</h2>
          <div class="owner-registration__how-registration-goes-cards">
            <owner-registration-process-description-card
              v-for="{ id, text, images } in $options.REGISTRATION_PROCESS_DESCRIPTION_CARDS"
              :key="id"
            >
              <template #step-count>
                {{ id }}
              </template>
              <template #text>
                {{ text }}
              </template>
              <template v-if="images && images.path" #image>
                <img
                  class="owner-registration__how-registration-goes-card-image"
                  :src="require(`@/assets/images/${images.path}`)"
                  :srcset="`${require(`@/assets/images/${images.retinaPath}`)} 2x`"
                  alt="Фотография объекта недвижимости"
                />
              </template>
            </owner-registration-process-description-card>
          </div>
          <hr />
        </div>
      </div>
      <div class="owner-registration__action-section">
        <div class="owner-registration__section-wrapper">
          <template v-if="!ownerRegistered">
            <h2 class="owner-registration__action-section-title">
              Регистрация собственника объекта недвижимости
            </h2>
            <v-stepper class="owner-registration__action-stepper">
              <template #header="{activeStep, changeActiveStep, stepsCount}">
                <div class="owner-registration__action-stepper-header">
                  <template v-for="(step, index) in stepsCount">
                    <div
                      v-if="index > 0"
                      :key="step + stepsCount"
                      class="owner-registration__action-stepper-step-divider"
                    ></div>
                    <v-stepper-step
                      :key="step"
                      class="owner-registration__action-stepper-step"
                      :complete="step < activeStep"
                      :active="step === activeStep"
                      :inactive="step > activeStep"
                      @click="activeStep > step && changeStep(step, changeActiveStep)"
                    >
                      {{ step }}
                      <template #label>{{ $options.REGISTRATION_STEPS_LABELS[step] }}</template>
                    </v-stepper-step>
                  </template>
                </div>
              </template>
              <template #1="{ changeActiveStep }">
                <owner-registration-about-owner
                  :user-data="userData"
                  :backend-error-message="backendErrorMessage"
                  :offer-url="offerUrl"
                  :terms-url="termsUrl"
                  @submitted="phoneSubmitted($event, changeActiveStep)"
                />
              </template>
              <template #2="{ changeActiveStep }">
                <owner-registration-send-code
                  :backend-error-message="backendErrorMessage"
                  :phone="userData.phone"
                  @goBack="changeStep(1, changeActiveStep)"
                  @resendCode="sendRegistrationCodeToOwnerPhone"
                  @submitted="codeSubmitted"
                />
              </template>
            </v-stepper>
          </template>
          <h2 v-else class="owner-registration__action-section-register-complete">Вы уже прошли регистрацию.</h2>
        </div>
      </div>
      <div class="owner-registration__sold-objects-section">
        <div class="owner-registration__section-wrapper">
          <objects-realty-swiper :objects="soldObjects" with-controls>
            <template #title>
              Проданные объекты
            </template>
          </objects-realty-swiper>
        </div>
      </div>
    </div>
  </match-media>
</template>

<script>
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import VStepper from '@/components/common/VStepper.vue'
import VStepperStep from '@/components/common/VStepperStep.vue'
import OwnerRegistrationSendCode from '@/components/Owner/Registration/SendCode.vue'
import OwnerRegistrationAboutOwner from '@/components/Owner/Registration/AboutOwner.vue'
import ObjectsRealtySwiper from '@/components/ObjectsRealty/Swiper/Swiper.vue'
import OwnerRegistrationProcessDescriptionCard from '@/components/Owner/Registration/ProcessDescriptionCard.vue'
// eslint-disable-next-line import/no-cycle
import redirectIfNetworkIssue from '@/router/utils'
import { MatchMedia } from 'vue-component-media-queries'
import { loadingService } from '@/services/loading'
import { notificationService } from '@/services/notification'
import { advertsService, authService } from '@/services/http'
import { MAIN_SITE_GENERAL_PAGE_ROUTE_NAME } from '@/constants/routes'

const REGISTRATION_STEPS_LABELS = {
  1: 'Общее',
  2: 'Подтверждение регистрации'
}
const REGISTRATION_PROCESS_DESCRIPTION_CARDS = [
  {
    id: 1,
    text: 'Получите приглашение на регистрацию от нашего агента.'
  },
  {
    id: 2,
    text: 'Заполните информацию о себе и об объекте недвижимости.'
  },
  {
    id: 3,
    text: 'Внимательно ознакомьтесь с публичной офертой о сотрудничестве.'
  },
  {
    id: 4,
    text: 'Подтвердите согласие с офертой путем ввода кода, отправленного на указанный телефон.'
  },
  {
    id: 5,
    text: `В течение 24 часов мы начнем продажу вашего дома —
          предложим текущим клиентам, запланируем фотоссессию и продумаем рекламную кампанию.`,
    images: {
      path: 'registration-description-image.png',
      retinaPath: 'registration-description-image@2x.png'
    }
  }
]
const REGISTRATION_OWNER_BREADCRUMBS = [
  {
    text: 'Главная',
    to: MAIN_SITE_GENERAL_PAGE_ROUTE_NAME,
    isOuterLink: true
  },
  {
    text: 'Регистрация собственника',
    disabled: true
  }
]

export default {
  REGISTRATION_OWNER_BREADCRUMBS,
  REGISTRATION_PROCESS_DESCRIPTION_CARDS,
  REGISTRATION_STEPS_LABELS,

  name: 'OwnerRegistration',
  components: {
    VStepperStep,
    OwnerRegistrationAboutOwner,
    OwnerRegistrationSendCode,
    VStepper,
    ObjectsRealtySwiper,
    OwnerRegistrationProcessDescriptionCard,
    VButtonGoBack,
    VBreadcrumbs,
    MatchMedia
  },
  data() {
    return {
      userData: {
        name: '',
        email: '',
        phone: '',
        landCadastralNumber: null,
        houseCadastralNumber: null
      },
      offerUrl: '',
      termsUrl: '',
      ownerRegistered: false,
      soldObjects: [],
      backendErrorMessage: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchSoldObjects()
      vm.fetchOffer()
    })
  },
  methods: {
    changeStep(step, changeActiveStep) {
      this.resetErrors()
      changeActiveStep(step)
    },
    resetErrors() {
      this.backendErrorMessage = ''
    },
    sendRegistrationCodeToOwnerPhone() {
      this.resetErrors()
      return authService.sendRegistrationCodeToOwnerPhone(this.userData.phone)
    },
    phoneSubmitted(newUserData, changeActiveStep) {
      loadingService.setGlobalLoading(true)
      this.userData = newUserData
      this.sendRegistrationCodeToOwnerPhone()
        .then(() => {
          changeActiveStep(2)
        })
        .catch(error => {
          if (error.status === 400) {
            this.backendErrorMessage = 'Пользователь с таким номером существует'
          } else {
            this.backendErrorMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          loadingService.setGlobalLoading(false)
        })
    },
    codeSubmitted(enteredCode) {
      this.resetErrors()
      loadingService.setGlobalLoading(true)
      authService
        .verifyRegistrationCode(this.userData.phone, enteredCode)
        .then(() => {
          authService
            .registerOwner({ ...this.userData })
            .then(() => {
              this.ownerRegistered = true
              notificationService.success('Информация получена. Ожидайте СМС-подтверждение о прохождении регистрации.')
            })
            .catch(error => {
              if (error.status === 400) {
                this.backendErrorMessage = 'Номер телефона не потвержден или истекло время ожидания'
              } else {
                this.backendErrorMessage = 'Неизвестная ошибка'
                throw error
              }
            })
        })
        .catch(error => {
          if (error.status === 400) {
            this.backendErrorMessage = 'Неверный код'
          } else {
            this.backendErrorMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          loadingService.setGlobalLoading(false)
        })
    },
    fetchOffer() {
      authService.getOffer().then(({ urlEula, urlTos }) => {
        this.offerUrl = urlEula
        this.termsUrl = urlTos
      })
    },
    fetchSoldObjects() {
      loadingService.setGlobalLoading(true)
      advertsService
        .getSoldAdverts()
        .then(({ results }) => {
          this.soldObjects = results
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          loadingService.setGlobalLoading(false)
        })
    },
    goToMain() {
      window.location.href = MAIN_SITE_GENERAL_PAGE_ROUTE_NAME
    }
  }
}
</script>
