<template>
  <div class="timer">
    <slot name="timer" :currentTime="currentTime" :resetTimer="resetTimer"> </slot>
  </div>
</template>

<script>
export default {
  name: 'VTimer',
  props: {
    time: {
      type: Number,
      default: 60
    }
  },

  data() {
    return {
      currentTime: this.time,
      timer: null
    }
  },

  mounted() {
    this.startTimer()
  },

  beforeDestroy() {
    this.stopTimer()
  },

  methods: {
    stopTimer() {
      clearInterval(this.timer)
    },
    resetTimer() {
      this.currentTime = this.time
      this.startTimer()
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1
        if (this.currentTime === 0) {
          this.stopTimer()
          this.$emit('time-end')
        }
      }, 1000)
    }
  }
}
</script>
