<template>
  <div class="owner-registration-process-description-card">
    <div class="owner-registration-process-description-card__step-count">
      <span><slot name="step-count"></slot></span>
    </div>
    <p class="owner-registration-process-description-card__text">
      <slot name="text"></slot>
    </p>
    <slot name="image"></slot>
  </div>
</template>

<script>
export default {
  name: 'OwnerRegistrationProcessDescriptionCard'
}
</script>
