<template>
  <div class="objects-realty-swiper-card">
    <div class="objects-realty-swiper-card__image-wrapper">
      <img class="objects-realty-swiper-card__image" :src="photo" :alt="name" />
    </div>
    <div class="objects-realty-swiper-card__params">
      {{ objectParams }}
    </div>
    <h3 class="objects-realty-swiper-card__title">{{ name }}</h3>
    <p class="objects-realty-swiper-card__description">
      {{ regionAddress }}
    </p>
    <span class="objects-realty-swiper-card__price">{{ formattedPrice }}</span>
  </div>
</template>

<script>
import { formatMoney } from '@/utils/formatters'
import pickBy from 'lodash.pickby'

export default {
  name: 'ObjectsRealtySwiperCard',
  props: {
    name: { type: String, default: '' },
    area: { type: Number, default: null },
    id: { type: Number, default: null },
    plottage: { type: Number, default: null },
    price: { type: Number, default: null },
    bedroomsCount: { type: Number, default: null },
    regionAddress: { type: String, default: '' },
    photo: { type: String, default: '' }
  },
  computed: {
    formattedPrice() {
      return formatMoney(this.price)
    },
    objectParams() {
      const params = {
        area: this.area ? `${this.area} кв.м.` : null,
        plottage: this.plottage ? `${this.plottage} сот.` : null,
        bedroomsCount: this.bedroomsCount ? `${this.bedroomsCount} сп.` : null
      }
      return Object.values(pickBy(params)).join(' • ')
    }
  }
}
</script>
