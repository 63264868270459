<template>
  <validation-observer v-slot="{ handleSubmit, reset }" tag="div" class="owner-registration-send-code">
    <v-form class="owner-registration-send-code__form" @submit.prevent="handleSubmit(onSubmit)">
      <template #default>
        <v-form-row class="owner-registration-send-code__form-row">
          <h2 class="owner-registration-send-code__form-row-title">
            Подтвердите номер телефона {{ phone }}.<br />
            Введите код из СМС
          </h2>
          <v-form-field rules="required|CodeLength:4">
            <template #default="{ validationErrors }">
              <v-input
                v-model="enteredCode"
                type="number"
                placeholder="Код"
                :is-error="!!validationErrors.length"
              ></v-input>
            </template>
          </v-form-field>
          <v-timer :time="40" class="owner-registration-send-code__timer">
            <template #timer="{ resetTimer, currentTime }">
              <v-button
                class="owner-registration-send-code__resend-code-button"
                type="button"
                is-text
                :disabled="!!currentTime"
                @click="sendCodeToOwnerAgain(resetTimer, reset)"
              >
                Отправить код повторно{{ currentTime ? ` через ${currentTime} сек.` : '' }}</v-button
              >
            </template>
          </v-timer>
        </v-form-row>
        <v-form-row v-if="!!backendErrorMessage" class="owner-registration-send-code__error">
          <v-form-error>
            {{ backendErrorMessage }}
          </v-form-error>
        </v-form-row>
      </template>
      <template #footer>
        <div class="owner-registration-send-code__footer">
          <v-button class="owner-registration-send-code__button--back" @click.prevent="$emit('goBack')">Назад</v-button>
          <v-button class="owner-registration-send-code__button--submit" primary type="submit"
            >Зарегистрироваться</v-button
          >
        </div>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormError from '@/components/form/VFormError.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VInput from '@/components/common/VInput.vue'
import VTimer from '@/components/common/VTimer.vue'

export default {
  name: 'OwnerRegistrationSendCode',
  components: { VTimer, VInput, VForm, VFormRow, VFormField, VFormError, VButton },
  props: {
    phone: {
      type: String,
      required: true
    },
    backendErrorMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      enteredCode: null
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submitted', this.enteredCode)
    },
    sendCodeToOwnerAgain(resetTimer, reset) {
      reset()
      resetTimer()
      this.enteredCode = null
      this.$emit('resendCode')
    }
  }
}
</script>
