<template>
  <swiper :ref="sliderRef" class="v-swiper" :options="options">
    <swiper-slide v-for="(object, index) in objects" :key="index" class="v-swiper__slide">
      <slot name="slideContent" :object="object"></slot>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

const DEFAULT_SWIPER_CONFIG = {
  slidesPerView: 'auto',
  spaceBetween: 16,
  direction: 'horizontal',
  navigation: {
    nextEl: '.next-button',
    prevEl: '.prev-button'
  },
  breakpoints: {
    480: {
      spaceBetween: 24
    }
  }
}

export default {
  DEFAULT_SWIPER_CONFIG,
  name: 'VSwiper',
  components: { Swiper, SwiperSlide },
  props: {
    objects: { type: Array, default: () => [] },
    options: { type: Object, default: () => DEFAULT_SWIPER_CONFIG },
    sliderRef: { type: String, default: '' }
  }
}
</script>
