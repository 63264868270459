var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_c('div',{staticClass:"owner-registration"},[_c('header',{staticClass:"owner-registration__header"},[_c('div',{staticClass:"owner-registration__section-wrapper"},[(!mobile)?_c('v-breadcrumbs',{staticClass:"owner-registration__breadcrumbs",attrs:{"separator":"•","items":_vm.$options.REGISTRATION_OWNER_BREADCRUMBS}}):_c('v-button-go-back',{staticClass:"owner-registration__back-button",on:{"click":_vm.goToMain}}),_c('h2',{staticClass:"owner-registration__header-title"},[_vm._v("Регистрация собственника")]),_c('p',{staticClass:"owner-registration__header-text"},[_vm._v(" Необязательно встречаться и подписывать договор. Мы ценим ваше время. Пройдите короткие 2 шага регистрации и мы начнем продажу вашего объекта недвижимости в течение 24 часов. ")])],1)]),_c('div',{staticClass:"owner-registration__how-registration-goes-section"},[_c('div',{staticClass:"owner-registration__section-wrapper"},[_c('h2',{staticClass:"owner-registration__how-registration-goes-title"},[_vm._v("Как проходит процесс регистрации?")]),_c('div',{staticClass:"owner-registration__how-registration-goes-cards"},_vm._l((_vm.$options.REGISTRATION_PROCESS_DESCRIPTION_CARDS),function(ref){
var id = ref.id;
var text = ref.text;
var images = ref.images;
return _c('owner-registration-process-description-card',{key:id,scopedSlots:_vm._u([{key:"step-count",fn:function(){return [_vm._v(" "+_vm._s(id)+" ")]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(text)+" ")]},proxy:true},(images && images.path)?{key:"image",fn:function(){return [_c('img',{staticClass:"owner-registration__how-registration-goes-card-image",attrs:{"src":require(("@/assets/images/" + (images.path))),"srcset":((require(("@/assets/images/" + (images.retinaPath)))) + " 2x"),"alt":"Фотография объекта недвижимости"}})]},proxy:true}:null],null,true)})}),1),_c('hr')])]),_c('div',{staticClass:"owner-registration__action-section"},[_c('div',{staticClass:"owner-registration__section-wrapper"},[(!_vm.ownerRegistered)?[_c('h2',{staticClass:"owner-registration__action-section-title"},[_vm._v(" Регистрация собственника объекта недвижимости ")]),_c('v-stepper',{staticClass:"owner-registration__action-stepper",scopedSlots:_vm._u([{key:"header",fn:function(ref){
var activeStep = ref.activeStep;
var changeActiveStep = ref.changeActiveStep;
var stepsCount = ref.stepsCount;
return [_c('div',{staticClass:"owner-registration__action-stepper-header"},[_vm._l((stepsCount),function(step,index){return [(index > 0)?_c('div',{key:step + stepsCount,staticClass:"owner-registration__action-stepper-step-divider"}):_vm._e(),_c('v-stepper-step',{key:step,staticClass:"owner-registration__action-stepper-step",attrs:{"complete":step < activeStep,"active":step === activeStep,"inactive":step > activeStep},on:{"click":function($event){activeStep > step && _vm.changeStep(step, changeActiveStep)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$options.REGISTRATION_STEPS_LABELS[step]))]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(step)+" ")])]})],2)]}},{key:"1",fn:function(ref){
var changeActiveStep = ref.changeActiveStep;
return [_c('owner-registration-about-owner',{attrs:{"user-data":_vm.userData,"backend-error-message":_vm.backendErrorMessage,"offer-url":_vm.offerUrl,"terms-url":_vm.termsUrl},on:{"submitted":function($event){return _vm.phoneSubmitted($event, changeActiveStep)}}})]}},{key:"2",fn:function(ref){
var changeActiveStep = ref.changeActiveStep;
return [_c('owner-registration-send-code',{attrs:{"backend-error-message":_vm.backendErrorMessage,"phone":_vm.userData.phone},on:{"goBack":function($event){return _vm.changeStep(1, changeActiveStep)},"resendCode":_vm.sendRegistrationCodeToOwnerPhone,"submitted":_vm.codeSubmitted}})]}}],null,true)})]:_c('h2',{staticClass:"owner-registration__action-section-register-complete"},[_vm._v("Вы уже прошли регистрацию.")])],2)]),_c('div',{staticClass:"owner-registration__sold-objects-section"},[_c('div',{staticClass:"owner-registration__section-wrapper"},[_c('objects-realty-swiper',{attrs:{"objects":_vm.soldObjects,"with-controls":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Проданные объекты ")]},proxy:true}],null,true)})],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }