<template>
  <div class="v-stepper">
    <slot name="header" :activeStep="activeStep" :change-active-step="changeActiveStep" :stepsCount="stepsCount"></slot>
    <template v-for="step in stepsCount">
      <slot v-if="step === activeStep" :name="step" :change-active-step="changeActiveStep"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VStepper',
  props: {
    currentStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeStep: 1
    }
  },
  computed: {
    stepsCount() {
      const { length } = Object.keys(this.$scopedSlots)
      return this.$scopedSlots.header ? length - 1 : length
    }
  },

  watch: {
    currentStep: {
      immediate: true,
      handler(step) {
        this.changeActiveStep(step)
      }
    }
  },
  methods: {
    changeActiveStep(step) {
      this.activeStep = step
    }
  }
}
</script>
