var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"owner-registration-about-owner",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"owner-registration-about-owner__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',{staticClass:"owner-registration-about-owner__form-row"},[_c('h2',{staticClass:"owner-registration-about-owner__form-row-title"},[_vm._v("Информация о собственнике")]),_c('div',{staticClass:"owner-registration-about-owner__form-fields-grid"},[_c('v-form-field',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"placeholder":"Имя и фамилия","is-error":!!validationErrors.length},model:{value:(_vm.localUserData.name),callback:function ($$v) {_vm.$set(_vm.localUserData, "name", $$v)},expression:"localUserData.name"}})]}}],null,true)}),_c('v-form-field',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"placeholder":"E-mail","is-error":!!validationErrors.length,"type":"email"},model:{value:(_vm.localUserData.email),callback:function ($$v) {_vm.$set(_vm.localUserData, "email", $$v)},expression:"localUserData.email"}})]}}],null,true)}),_c('v-form-field',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"placeholder":"Телефон","is-error":!!validationErrors.length},model:{value:(_vm.localUserData.phone),callback:function ($$v) {_vm.$set(_vm.localUserData, "phone", $$v)},expression:"localUserData.phone"}})]}}],null,true)})],1)]),_c('v-form-row',{staticClass:"owner-registration-about-owner__form-row"},[_c('h2',{staticClass:"owner-registration-about-owner__form-row-title"},[_vm._v("Информация об объекте")]),_c('div',{staticClass:"owner-registration-about-owner__form-fields-grid"},[_c('v-form-field',{attrs:{"rules":"required|minWithoutDividers:12,:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"mask":_vm.$options.CADASTRAL_NUMBER_MASK,"placeholder":"Кадастровый номер дома","is-error":!!validationErrors.length},model:{value:(_vm.localUserData.houseCadastralNumber),callback:function ($$v) {_vm.$set(_vm.localUserData, "houseCadastralNumber", $$v)},expression:"localUserData.houseCadastralNumber"}})]}}],null,true)}),_c('v-form-field',{attrs:{"rules":"required|minWithoutDividers:12,:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"mask":_vm.$options.CADASTRAL_NUMBER_MASK,"placeholder":"Кадастровый номер участка","is-error":!!validationErrors.length},model:{value:(_vm.localUserData.landCadastralNumber),callback:function ($$v) {_vm.$set(_vm.localUserData, "landCadastralNumber", $$v)},expression:"localUserData.landCadastralNumber"}})]}}],null,true)})],1)]),(!!_vm.backendErrorMessage)?_c('v-form-row',{staticClass:"owner-registration-about-owner__error"},[_c('v-form-error',[_vm._v(_vm._s(_vm.backendErrorMessage))])],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"owner-registration-about-owner__footer"},[_c('v-checkbox',{staticClass:"owner-registration-about-owner__agreement-checkbox",class:{ 'owner-registration-about-owner__agreement-checkbox--checked': _vm.isAgreement },model:{value:(_vm.isAgreement),callback:function ($$v) {_vm.isAgreement=$$v},expression:"isAgreement"}},[_vm._v("Я принимаю "),_c('v-button',{staticClass:"owner-registration-about-owner__offer-link",attrs:{"is-text":"","href":_vm.offerUrl,"target":"_blank"}},[_vm._v("договор оферты")]),_vm._v(" и согласен на обработку "),_c('v-button',{staticClass:"owner-registration-about-owner__offer-link",attrs:{"is-text":"","href":_vm.termsUrl,"target":"_blank"}},[_vm._v("персональных данных")])],1),_c('v-button',{staticClass:"owner-registration-about-owner__button--submit",attrs:{"primary":"","type":"submit","disabled":!_vm.isAgreement}},[_vm._v("Далее")])],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }