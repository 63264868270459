<template>
  <div class="objects-realty-swiper">
    <header class="objects-realty-swiper__header">
      <h2 class="objects-realty-swiper__title"><slot name="title"></slot></h2>
      <router-link v-if="showAllLink" :to="showAllLink" class="objects-realty-swiper__show-all-link"
        >См.все</router-link
      >
      <div v-if="withControls" class="objects-realty-swiper__controls">
        <div class="objects-realty-swiper__control-button prev-button">
          <v-swiper-control direction="left"></v-swiper-control>
        </div>
        <div class="objects-realty-swiper__control-button next-button">
          <v-swiper-control direction="right"></v-swiper-control>
        </div>
      </div>
    </header>
    <v-swiper :objects="objects">
      <template #slideContent="{ object }">
        <a :href="object.url" target="_blank">
          <objects-realty-swiper-card v-bind="object"></objects-realty-swiper-card>
        </a>
      </template>
    </v-swiper>
  </div>
</template>

<script>
import VSwiperControl from '@/components/icons/VSwiperControl.vue'
import VSwiper from '@/components/common/VSwiper.vue'
import ObjectsRealtySwiperCard from '@/components/ObjectsRealty/Swiper/Card.vue'

export default {
  name: 'ObjectsRealtySwiper',
  components: { ObjectsRealtySwiperCard, VSwiper, VSwiperControl },
  props: {
    objects: { type: Array, default: () => [] },
    withControls: { type: Boolean, default: false },
    showAllLink: { type: [String, Object], default: '' }
  }
}
</script>
