<template>
  <div class="stepper-step" :class="classes" v-on="$listeners">
    <div class="stepper-step__point">
      <slot></slot>
    </div>
    <span class="stepper-step__label"> <slot name="label"></slot></span>
  </div>
</template>

<script>
export default {
  name: 'VStepperStep',
  props: {
    complete: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'stepper-step--active': this.active,
        'stepper-step--complete': this.complete,
        'stepper-step--inactive': this.inactive
      }
    }
  }
}
</script>
